import React, {useState, useEffect} from 'react'
import 'components/Modal/Modal.css'
import {Formik, Form, Field} from 'formik'
import {useSelector, useDispatch} from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import {setEvenData} from 'redux/textSlice'

function Edit({modEdit, setModEdit, setBrands}) {
    const identifier = useSelector(state => state.text.slidId)
    const dispatch = useDispatch()

    useEffect(() => {
        let x = JSON.parse(localStorage.getItem("Atoken"));
        axios
            .get(`https://api.icd-academy.com/api/Contact/${identifier}`, {
                headers: {
                    Authorization: "Bearer " + x,
                },
            })
            .then((resp) => dispatch(setEvenData(resp.data)));
    }, [identifier && identifier]);

    const evenSlid = useSelector(state => state.text.evenData)

    const handleEditModal = () => {
        setModEdit(false);
        let x = JSON.parse(localStorage.getItem("Atoken"));
        axios
            .get("https://api.icd-academy.com/api/Contact", {
                headers: {
                    Authorization: "Bearer " + x,
                },
            })
            .then((resp) => setBrands(resp.data));
    }
    return (
        <div>

            <Modal className='modded' show={modEdit}>
                <div className="modal-content">
                    <Modal.Header>
                        <button onClick={() => setModEdit(false)} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <Formik
                            enableReinitialize={true}
                            initialValues={
                                {
                                    openingHourse: evenSlid && evenSlid.openingHourse,
                                    phone: evenSlid && evenSlid.phone,
                                    email: evenSlid && evenSlid.email,
                                    address: evenSlid && evenSlid.address,
                                }
                            }
                            onSubmit={(x) => {
                                const formdata = new FormData();
                                formdata.append("Id",evenSlid&&evenSlid.id)
                                formdata.append("openingHourse", x.openingHourse)
                                formdata.append("phone", x.phone)
                                formdata.append("email", x.email)
                                formdata.append("address", x.address)

                                let token = JSON.parse(localStorage.getItem("Atoken"));
                                let url = `https://api.icd-academy.com/api/Contact/update/${evenSlid && evenSlid.id}`
                                fetch(url, {
                                    method: 'PUT',
                                    headers: {
                                        "Authorization": "Bearer " + token,
                                    },
                                    body: formdata,
                                })
                                    .then(resp => {
                                            console.log(resp.body)
                                            if (resp.status === 204) {
                                                handleEditModal()
                                            }
                                        }
                                    )
                            }
                            }
                        >
                            <Form>

                                <div className="slid-area">
                                    <label htmlFor="openingHourse">Title</label>
                                    <Field name='openingHourse' type="text" id='openingHourse' />
                                </div>

                                <div className="slid-area">
                                    <label htmlFor="phone">Title</label>
                                    <Field name='phone' type="text" id='phone' />
                                </div>

                                <div className="slid-area">
                                    <label htmlFor="email">Title</label>
                                    <Field name='email' type="text" id='email' />
                                </div>

                                <div className="slid-area">
                                    <label htmlFor="address">Title</label>
                                    <Field name='address' type="text" id='address' />
                                </div>
                                <div className="slid-btn">
                                    <input className='sub-input' type="submit"/></div>
                            </Form>
                        </Formik>

                    </Modal.Body>
                </div>

            </Modal>


        </div>
    )
}

export default Edit