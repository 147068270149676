import React,{useEffect,useState} from 'react'
import {decodeToken} from "react-jwt";
import 'assets/css/Home.css'

function Home() {
    const [token,setToken] = useState('')
    useEffect(()=>
    {
        setToken(localStorage.getItem("token"))
    },[])
    let aliyev = decodeToken(token)
    return (
        <div className='d-flex w-100 justify-content-center'>
            <img src="https://mobilemonkey.com/wp-content/uploads/2020/12/welcome-greeting-message.png" alt=""/>
        </div>
    )
}

export default Home