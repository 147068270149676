import React, {useEffect, useState} from 'react'
import 'assets/css/Brands.css'
import axios from 'axios'
import AddModal from './BrandModal/addBrand'
import Edit from './BrandModal/editModal'
import Remove from './BrandModal/delBrand'
import {useDispatch} from 'react-redux'
import {setText, setId} from 'redux/textSlice'

function Courses() {
    const [show, setShow] = useState(false)
    const [gallery, setGallery] = useState([])
    const [modEdit, setModEdit] = useState(false)
    const [remshow, setRemShow] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        let x = JSON.parse(localStorage.getItem("Atoken"));
        axios
            .get("https://api.icd-academy.com/api/Course", {
                headers: {
                    Authorization: "Bearer " + x,
                },
            })
            .then((resp) => setGallery(resp.data));
    }, [])

    const handleRemove = (e) => {
        dispatch(setText(e))
        setRemShow(true)
    }
    return (
        <div className='brands'>
            <div className="add-brands">
                <h1>Courses</h1>
                <p className='new-p' onClick={() => setShow(true)} data-toggle="modal"
                   data-target="#exampleModal">Create Course</p>
            </div>
            <AddModal show={show} setShow={setShow} setBrands={setGallery}/>
            <div className="slider-list">
                <div className="slid-head">
                </div>
                {
                    gallery && gallery.map(e => {
                        return (
                            <div key={e.id} className="slid-one">
                                <img src={`https://api.icd-academy.com/img/${e.imageName}`} alt='student slider image' />
                                <p>{e.courseName}</p>
                                <div className="func-btn">
                                    <p className='slid-edit' onClick={() => {
                                        setModEdit(true)
                                        dispatch(setId(e.id))
                                    }}>edit</p>
                                    <p onClick={() => handleRemove(e)} className='slid-del'>delete</p>
                                    <Remove remshow={remshow} setRemShow={setRemShow} setBrands={setGallery}/>
                                    <Edit modEdit={modEdit} setModEdit={setModEdit} setBrands={setGallery}/>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default Courses