import {object,string} from 'yup'


const loginVal = object(
    {
        username:string().required("username-i daxil edin").min(6),
        password:string().required("Sifre daxil edin").min(9)
    }
)


export default loginVal