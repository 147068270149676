import React, {useState} from 'react'
import './Modal.css'
import {Formik, Form, Field} from 'formik'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'

function ModalView({show, setShow, setSlid}) {

    const [imager, setImager] = useState(null)
    const navigate = useNavigate()

    return (
        <div className='mod-all'>
            <Modal show={show}>
                <div className="modal-content">
                    <Modal.Header>
                        <button onClick={() => setShow(false)} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="modal-body">

                        <Formik
                            initialValues={
                                {
                                    desc: ""
                                }
                            }
                            onSubmit={(x) => {
                                const formdata = new FormData();
                                formdata.append("imageFiles", imager)


                                let token = JSON.parse(localStorage.getItem("Atoken"));

                                let url = "https://api.icd-academy.com/api/Slider/create"
                                fetch(url, {
                                    method: 'post',
                                    headers: {
                                        "Authorization": "Bearer " + token,
                                    },
                                    body: formdata,
                                })
                                    .then(resp => {
                                            if (resp.status === 204) {
                                                setShow(false);
                                                let x = JSON.parse(localStorage.getItem("Atoken"));
                                                axios
                                                    .get("https://api.icd-academy.com/api/Slider", {
                                                        headers: {
                                                            Authorization: "Bearer " + x,
                                                        },
                                                    })
                                                    .then((resp) => setSlid(resp.data));
                                            }
                                        }
                                    )
                            }}
                        >
                            <Form>
                                <div className="slid-area">
                                    <label htmlFor="main">Select image</label>
                                    <div className="select-file">
                                        <i className="bi bi-link-45deg"></i>
                                        <input onChange={(e) => setImager(e.target.files[0])} name='image' type="file"
                                               className='custom-file-input' accept="image/jpeg" id='main'/>
                                    </div>
                                </div>
                                <div className="slid-btn">
                                    <input className='sub-input' type="submit"/></div>
                            </Form>
                        </Formik>

                    </Modal.Body>
                </div>

            </Modal>


        </div>
    )
}

export default ModalView