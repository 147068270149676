import {useEffect} from 'react';
import './App.css';
import Nav from './components/Nav/Nav'
import { Routes, Route, useLocation } from 'react-router-dom'
import Login from './Pages/Login'
import ProtectedRoutes from './Routes/ProtectedRoutes'
import {useDispatch} from 'react-redux'
import {setLog} from './redux/loginSlice'
import Home from "./Pages/Home/Home";
import Slider from "./Pages/Slider";
import StudentSlider from "./Pages/StudentSlider/StudentSlider";
import About from "./Pages/About/About";
import HomeTitle from "./Pages/HomeTitle/HomeTitle";
import Courses from "./Pages/Courses/Courses";
import Goals from "./Pages/Goals/Goals";
import Contact from "./Pages/Contact/Contact";
import Teacher from "./Pages/Teachers/Teacher";

function App() {

  const dispatch = useDispatch()

  useEffect(()=>
  {

    if (JSON.parse(localStorage.getItem("route")) === null)
    {
      localStorage.setItem("route",JSON.stringify(false))
      dispatch(setLog(JSON.parse(localStorage.getItem("route"))))
    }
    else
    {
      dispatch(setLog(JSON.parse(localStorage.getItem("route"))))
    }

  },[])

  let loc = useLocation();

  return (
    <div className="App">
        {loc.pathname !== "/"?<Nav/>:null}
        <Routes>
          <Route path='/' element={<Login/>}/>
          <Route element={<ProtectedRoutes/>}>
            <Route path='/home' element={<Home/>}/>
            <Route path='/footer-slider' element={<Slider/>}/>
            <Route path='/student-slider' element={<StudentSlider/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/home-info' element={<HomeTitle/>}/>
            <Route path='/courses' element={<Courses/>}/>
            <Route path='/goals' element={<Goals/>}/>
            <Route path='/contacts' element={<Contact/>}/>
            <Route path='/teachers' element={<Teacher/>}/>

          </Route>
        </Routes>

    </div>
  );
}

export default App;
