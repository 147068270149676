import React, {useEffect, useState} from 'react'
import 'assets/css/Brands.css'
import axios from 'axios'
import Edit from './BrandModal/editModal'
import {useDispatch} from 'react-redux'
import {setText, setId} from 'redux/textSlice'

function HomeTitle() {
    const [show, setShow] = useState(false)
    const [gallery, setGallery] = useState([])
    const [modEdit, setModEdit] = useState(false)
    const [remshow, setRemShow] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        let x = JSON.parse(localStorage.getItem("Atoken"));
        axios
            .get("https://api.icd-academy.com/api/HomeInfo", {
                headers: {
                    Authorization: "Bearer " + x,
                },
            })
            .then((resp) => setGallery(resp.data));
    }, [])
    const handleRemove = (e) => {
        dispatch(setText(e))
        setRemShow(true)
    }
    return (
        <div className='brands'>
            <div className="add-brands">
                <h1>Home Title</h1>
            </div>
            <div className="slider-list">
                <div className="slid-head">
                </div>
                {
                    gallery && gallery.map(e => {
                        return (
                            <div key={e.id} className="slid-one">
                                <p>{e.title}</p>
                                <div className="func-btn">
                                    <p className='slid-edit' onClick={() => {
                                        setModEdit(true)
                                        dispatch(setId(e.id))
                                    }}>edit</p>
                                    <Edit modEdit={modEdit} setModEdit={setModEdit} setBrands={setGallery}/>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default HomeTitle