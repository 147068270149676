import React, {useState} from 'react'
import 'components/Modal/Modal.css'
import {Formik, Form, Field} from 'formik'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'

function AddModal({show, setShow, setBrands}) {
    const [imager, setImager] = useState(null)
    return (
        <div className='mod-all'>
            <Modal show={show}>
                <div className="modal-content">
                    <Modal.Header>
                        <button onClick={() => setShow(false)} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="modal-body">

                        <Formik
                            initialValues={
                                {
                                    courseName: "",
                                    learnMaterial: "",
                                    month: "",
                                    modules: ""
                                }
                            }
                            onSubmit={(x) => {
                                const formdata = new FormData();
                                formdata.append("imageFiles", imager)
                                formdata.append("courseName", x.courseName)
                                formdata.append("learnMaterial", x.learnMaterial)
                                formdata.append("month", x.month)
                                formdata.append("modules", x.modules)

                                let token = JSON.parse(localStorage.getItem("Atoken"));
                                let url = "https://api.icd-academy.com/api/Course/create"

                                fetch(url, {
                                    method: 'POST',
                                    headers: {
                                        "Authorization": "Bearer " + token,
                                    },
                                    body: formdata,
                                })
                                    .then(resp => {
                                            if (resp.status === 204) {
                                                setShow(false);
                                                let x = JSON.parse(localStorage.getItem("Atoken"));
                                                axios
                                                    .get("https://api.icd-academy.com/api/Course", {
                                                        headers: {
                                                            Authorization: "Bearer " + x,
                                                        },
                                                    })
                                                    .then((resp) => setBrands(resp.data));
                                            }
                                        }
                                    ).catch(err => console.log(err))
                            }
                            }
                        >
                            <Form>
                                <div className="slid-area">
                                    <label htmlFor="courseName">Course Name</label>
                                    <Field name='courseName' type="text" id='courseName' />
                                </div>

                                <div className="slid-area">
                                    <label htmlFor="learnMaterial">Detail Info</label>
                                    <Field name='learnMaterial' type="text" id='learnMaterial' />
                                </div>

                                <div className="slid-area">
                                    <label htmlFor="month">Month</label>
                                    <Field name='month' type="text" id='month' />
                                </div>

                                <div className="slid-area">
                                    <label htmlFor="modules">Modules</label>
                                    <Field name='modules' type="text" id='modules' />
                                </div>

                                <div className="slid-area">
                                    <label htmlFor="imageName">Image</label>
                                    <div className="select-file">
                                        <i className="bi bi-link-45deg"></i>
                                        <input onChange={(e) => setImager(e.target.files[0])} name='imageFiles'
                                               type="file" className='custom-file-input' accept="image/*"
                                               id='imageFiles'/>
                                    </div>
                                </div>
                                <div className="slid-btn">
                                    <input className='sub-input' type="submit"/></div>
                            </Form>
                        </Formik>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    )
}

export default AddModal