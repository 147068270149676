import React, { useState } from 'react'
import './Nav.css'
import {Link,useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {setLog} from 'redux/loginSlice'
import logo from '../../assets/Images/Romano Maximo_Logo.png'

function Nav() {

    const [resp, setResp] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <>
            <div className={`navs`}>
                <div className={`  ${resp}`}>
                    <div className="nav-img">
                        <img src='https://www.icd-academy.com/static/media/Logo%20ICD_White.bd1fb7972f62675d4957.png'  alt="" />
                    </div>
                    
                </div>
                <Link to='/courses' className='sidel'><span></span>Courses</Link>
                <Link to='/teachers' className='sidel'><span></span>Teachers</Link>
                <Link to='/about' className='sidel'><span></span>About</Link>
                <Link to='/contacts' className='sidel'><span></span>Contacts</Link>
                <Link to='/home-info' className='sidel'><span></span>Home Info</Link>
                <Link to='/goals' className='sidel'><span></span>Goals</Link>
                <Link to='/footer-slider' className='sidel'><span></span>Footer Slider</Link>
                <Link to='/student-slider' className='sidel'><span></span>Student Slider</Link>
                <hr style={{backgroundColor:"white"}}/>
                <p onClick={()=>
                {
                    localStorage.setItem("Atoken",null)
                    localStorage.setItem("route",JSON.stringify(false))
                    dispatch(setLog(JSON.parse(localStorage.getItem("route"))))
                    navigate("/")
                }} className='sidel mt-1'><span></span>Log out</p>
            </div>

        </>
    )
}

export default Nav